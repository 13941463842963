import { firestore } from 'app/services';
import { map, shareReplay } from 'rxjs/operators';
import { collectionData } from '@fuse/utils/rxfire';
import store from 'app/store';
import { combineLatest } from 'rxjs';
import EnrollmentFacade, { Enrollment, getStatusScore, parseCourseEnrollment } from './EnrollmentFacade';
import firebase from 'firebase/app';
import { Picture } from './PictureFacade';
import { User } from './AuthFacade';
import { setCourses } from 'app/store/actions';

export type ChildrenType = 'module' | 'video';

export interface AccessControl {
	type: string;
	openingDate: firebase.firestore.Timestamp | null;
	closingDate: firebase.firestore.Timestamp | null;
	daysAfterPurchase: number;
}

export interface Course {
	id: string;
	position: number;
	slug: string;
	title: string;
	subtitle: string;
	language: 'pt-BR' | 'pt-PT' | 'es' | 'en-US';
	thumbnail: string;
	locked: boolean;
	archived: boolean;
	packages: string[];
	blockMessage: string;
	blockButtons: CourseBlockButton[];
	banners: CourseBanner[];
	childrenType: ChildrenType;
	layoutPosition: 'top' | 'middle' | 'bottom';
	theme: string;
	picture: Picture;
	accessControl: AccessControl;

	createdAt: number | firebase.firestore.FieldValue;
	updatedAt: number | firebase.firestore.FieldValue;
	archivedAt: number | firebase.firestore.FieldValue;

	enrollment: Enrollment;
	isEnrolled: boolean;
	isActive: boolean;
}

export interface CourseBlockButton {
	title: string;
	link: string;
	color?: 'inherit' | 'default' | 'primary' | 'secondary';
}

export interface CourseBanner {
	position: number;
	title: string;
	thumbnail: string;
	link?: string;
}

class CourseFacade {
	collection = firestore.collection('courses');

	courses$ = collectionData<Course>(this.collection.where('language', '==', 'pt-BR'), 'id').pipe(
		map(list => list.filter(item => item.archived === false)),
		map(list => list.sort((a, b) => a.position - b.position)),
		shareReplay({ bufferSize: 1, refCount: false }),
	);

	coursesEnrollments$(user: User) {
		const enrollments$ = EnrollmentFacade.getAllWithUserId$(user.id);
		return enrollments$.pipe(
			map(enrollments => {
				const coursesEnrollments: Record<string, Enrollment> = {};

				for (const enrollment of enrollments) {
					for (const course of enrollment?.class?.courses) {
						const oldCourseEnrollment = coursesEnrollments[course.id];

						if (
							!oldCourseEnrollment ||
							getStatusScore(oldCourseEnrollment.status) < getStatusScore(enrollment.status)
						) {
							coursesEnrollments[course.id] = enrollment;
						}
					}
				}

				return coursesEnrollments;
			}),
		);
	}

	getCourses$(user: User) {
		return combineLatest([this.courses$, this.coursesEnrollments$(user)]).pipe(
			map(([courses, coursesEnrollments]) => {
				for (const course of courses) {
					parseCourseEnrollment(coursesEnrollments[course.id], course);
				}

				return courses.filter(course => {
					const isHidden = course.accessControl.type === 'hidden';
					const isEnrolled = course.isEnrolled;

					return !isHidden || isEnrolled;
				});
			}),
		);
	}

	loadCourses(user: User) {
		return this.getCourses$(user).subscribe(courses => {
			store.dispatch(
				setCourses(
					courses,
					courses.filter(course => course.isEnrolled),
				),
			);
		});
	}

	getCourse$(user: User, slug: string) {
		return this.getCourses$(user).pipe(map(list => (slug && list.find(it => it.slug === slug)) || null));
	}
}

const instance = new CourseFacade();

export default instance;
