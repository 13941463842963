import { Navigation } from 'app/facades/NavigationFacade';

const navigationConfig: Navigation[] = [
	{
		id: 'user-interface',
		title: '',
		type: 'group',
		icon: 'web',
		children: [
			{
				id: 'courses',
				title: 'Meus Cursos',
				type: 'item',
				icon: 'school',
				url: '/courses',
			},
			{
				id: 'discover',
				title: 'Descubra',
				type: 'item',
				icon: 'collections_bookmark',
				url: '/discover',
			},
			{
				id: 'app',
				title: 'Acelerador do Inglés Prime',
				type: 'item',
				icon: 'phone_iphone',
				url: 'https://app.marcelodaluz.app/login',
				target: '_blank',
			},
			{
				id: 'talk-to-us',
				title: 'Fale com a Gente',
				type: 'item',
				icon: 'info',
				url: '/talk-to-us',
			},
		],
	},
];

export default navigationConfig;
