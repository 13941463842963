import React from 'react';
import { authRoles } from 'app/auth';
import { RouteConfig } from 'app/configs';

const CoursesConfig: RouteConfig = {
	auth: authRoles.user,
	routes: [
		{
			path: '/courses',
			component: React.lazy(() => import('./Courses')),
			exact: true,
		},
	],
};

export default CoursesConfig;
