import React from 'react';
import { RouteConfig } from 'app/configs';
import routes from 'app/configs/routesConfig';

type Dispatch = React.Dispatch<React.SetStateAction<RouteConfig[]>>;

type Props = { children: React.ReactNode };

const RouteStateContext = React.createContext<RouteConfig[]>([]);
const RouteDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function useRouteState() {
	return React.useContext(RouteStateContext);
}

function useRouteDispatch() {
	const context = React.useContext(RouteDispatchContext);

	if (!context) {
		throw new Error(`useRouteDispatch must be used within a RouteProvider`);
	}

	return context;
}

function RouteProvider({ children }: Props) {
	const [user, setUser] = React.useState<RouteConfig[]>(routes);

	return (
		<RouteStateContext.Provider value={user}>
			<RouteDispatchContext.Provider value={setUser}>{children}</RouteDispatchContext.Provider>
		</RouteStateContext.Provider>
	);
}

export { RouteProvider, useRouteState, useRouteDispatch };
