import { RouteConfig } from 'app/configs';
import React from 'react';

const TalkToUsConfig: RouteConfig = {
	routes: [
		{
			path: '/talk-to-us',
			component: React.lazy(() => import('./TalkToUs')),
		},
	],
};

export default TalkToUsConfig;
