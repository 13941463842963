export const SET_OPEN_PROFILE_DIALOG = '[PROFILE_DIALOG] SET OPEN';
export const SET_LOADING_PROFILE_DIALOG = '[PROFILE_DIALOG] SET LOADING';

export function setOpenProfileDialog(open: boolean) {
	return {
		type: SET_OPEN_PROFILE_DIALOG,
		payload: open,
	};
}

export function setLoadingProfileDialog(loading: boolean) {
	return {
		type: SET_LOADING_PROFILE_DIALOG,
		payload: loading,
	};
}
