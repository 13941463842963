import React from 'react';
import { matchRoutes } from 'react-router-config';
import { useHistory, useLocation } from 'react-router-dom';
import FuseUtils from '@fuse/FuseUtils';
import { useRouteState } from 'app/contexts/RouteContext';
import { useAuthentication } from '../contexts/AuthenticationContext';
import LoadingPage from 'app/pages/loading/LoadingPage';

type Props = { children: React.ReactNode };

function Authorization({ children }: Props) {
	const { pathname, state } = useLocation<any>();
	const history = useHistory();
	const routes = useRouteState();
	const { user, loading } = useAuthentication();

	const matched = loading ? false : matchRoutes(routes, pathname)[0];

	const accessGranted = matched ? FuseUtils.hasPermission(matched.route.auth, user ? 'user' : '') : false;

	React.useEffect(() => {
		if (!loading && !accessGranted) {
			const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';

			if (!user) {
				if (pathname !== '/entrar') {
					history.push({
						pathname: '/entrar',
						state: { redirectUrl: pathname },
					});
				}
			} else {
				history.push({
					pathname: redirectUrl,
				});
			}
		}
	}, [loading, accessGranted, state?.redirectUrl, pathname, history, state, user]);

	return <>{loading ? <LoadingPage /> : accessGranted ? children : 'Acesso negado!'}</>;
}

export default React.memo(Authorization);
