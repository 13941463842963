import { Course } from 'app/facades/CourseFacade';

export const SET_COURSES = '[COURSES] SET COURSES';
export const SET_COURSES_LOADING = '[COURSES] SET LOADING';

export function setCourses(courses: (Course | null)[], ownedCourses: Course[]) {
	return {
		type: SET_COURSES,
		payload: { courses, ownedCourses },
	};
}

export function setLoading(loading: boolean) {
	return {
		type: SET_COURSES_LOADING,
		payload: loading,
	};
}
