import React from 'react';
import { AppBar, Hidden, makeStyles, Theme, Toolbar, useScrollTrigger, Slide } from '@material-ui/core';
import UserMenu from 'app/layout/components/UserMenu';
import { Link } from 'react-router-dom';
import NavbarMobileToggleButton from './NavbarMobileToggleButton';
import { useAuthentication } from 'app/contexts/AuthenticationContext';

const useStyles = makeStyles((theme: Theme) => ({
	logoWrapper: {
		height: theme.spacing(6),
		width: theme.spacing(6),
	},
	pages: {
		flexGrow: 1,
		display: 'flex',
		'& a': {
			margin: theme.spacing(0, 2),
			...theme.typography.body2,
			'& button': {
				textTransform: 'none',
			},
		},
	},
}));

const HideOnScroll: React.FunctionComponent<{ scrollbarRef?: React.RefObject<HTMLDivElement> }> = ({
	scrollbarRef,
	children,
}) => {
	const [, setCount] = React.useState(0);
	const trigger = useScrollTrigger({ target: scrollbarRef!.current || undefined });

	React.useEffect(() => {
		const timer = setTimeout(() => setCount(1), 200);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children as any}
		</Slide>
	);
};

function ToolbarLayout1({ scrollbarRef }: { scrollbarRef?: React.RefObject<HTMLDivElement> }) {
	const styles = useStyles();

	const { user } = useAuthentication();

	return (
		<>
			<HideOnScroll scrollbarRef={scrollbarRef}>
				<AppBar color="default">
					<Toolbar className="p-0 md:px-16">
						<Hidden mdUp>
							<NavbarMobileToggleButton className="w-48 h-48 p-0" />
						</Hidden>

						<Hidden xsDown>
							<Link to="/" className={styles.logoWrapper}>
								<img src="/assets/imgs/logo.png" alt="logo" />
							</Link>
						</Hidden>

						<Hidden xsDown>
							<div className={styles.pages}>
								<Link to={'/courses'}>Meus Cursos</Link>
								<Link to={'/discover'}>Descubra</Link>
								<Hidden smDown>
									<a
										href="https://app.marcelodaluz.app/login"
										target="_blank"
										rel="noreferrer"
									>
										Acelerador do Inglês prime
									</a>
								</Hidden>
								{user?.language === 'pt-BR' && (
									<Hidden smDown>
										<a
											href="https://aceleradordoingles.com/acelerador-do-ingles-ia/"
											target="_blank"
											rel="noreferrer"
										>
											AI
										</a>
									</Hidden>
								)}
							</div>
						</Hidden>

						<div className="flex-1" />

						<UserMenu />
					</Toolbar>
				</AppBar>
			</HideOnScroll>
			<Toolbar />
		</>
	);
}

export default ToolbarLayout1;
