import { combineReducers } from 'redux';
import settings from './settings.reducer';
import dialog from './dialog.reducer';
import message from './message.reducer';
import navbar from './navbar.reducer';
import navigation from './navigation.reducer';

const fuseReducers = combineReducers({
	settings,
	dialog,
	message,
	navbar,
	navigation,
});

export default fuseReducers;
