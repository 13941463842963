import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
} from '@material-ui/core';
import { AppThunk } from 'app/store';
import React from 'react';

export const OPEN_DIALOG = '[DIALOG] OPEN';
export const CLOSE_DIALOG = '[DIALOG] CLOSE';

export type SimpleDialogOptions = {
	title: string;
	message: string;
	buttons?: DialogButton[];
	onClose?: (() => void) | null;
};

export type DialogButton = {
	title: string;
	color?: 'inherit' | 'default' | 'primary' | 'secondary';
	link?: string;
	handler?: () => void;
};

export function closeDialog(button?: DialogButton): AppThunk {
	return dispatch => {
		if (button?.link) {
			window.open(button?.link, '_blank');
		} else if (button?.handler) {
			button.handler();
		}

		return dispatch({
			type: CLOSE_DIALOG,
		});
	};
}

export function openDialog(options: Partial<DialogProps>) {
	return {
		type: OPEN_DIALOG,
		payload: options,
	};
}

export function openSimpleDialog({ title, message, buttons, onClose }: SimpleDialogOptions): AppThunk {
	return dispatch => {
		const mButtons = buttons || [
			{
				title: 'Fechar',
			},
		];

		const children = (
			<>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-line' }}>
						{message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{mButtons.map((button, i) => (
						<Button
							key={i}
							onClick={() => dispatch(closeDialog(button))}
							color={button.color || 'default'}
						>
							{button.title}
						</Button>
					))}
				</DialogActions>
			</>
		);

		return dispatch({
			type: OPEN_DIALOG,
			payload: {
				children,
				onClose: () => {
					if (onClose) {
						onClose();
					}

					if (onClose !== null) {
						dispatch({
							type: CLOSE_DIALOG,
						});
					}
				},
			},
		});
	};
}
