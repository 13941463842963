import * as Actions from '../actions';
import { Reducer } from 'redux';
import { MyAction } from 'app/store';
import { BreadcrumbItem } from 'app/facades/BreadcrumbFacade';

type BreadcrumbState = {
	prefix: string | null;
	breadcrumb: BreadcrumbItem[];
};

const initialState: BreadcrumbState = {
	prefix: null,
	breadcrumb: [],
};

const pages: Reducer<BreadcrumbState, MyAction> = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_BREADCRUMB: {
			return {
				...state,
				breadcrumb: action.payload,
			};
		}
		case Actions.SET_BREADCRUMB_PREFIX: {
			return {
				...state,
				prefix: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};

export default pages;
