import React from 'react';
import { AppBar, makeStyles, Theme, Toolbar } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useTypedSelector } from 'app/store';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		textAlign: 'center',
		padding: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'between',
		},
	},
	list: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		flexGrow: 1,
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'flex-end',
		},
		'& li': {
			lineHeight: '1.25',
			padding: theme.spacing(1, 0.5),
			letterSpacing: '0.025em',
		},
		'& li a': {
			color: theme.palette.text.disabled,
			'&:hover': {
				color: theme.palette.primary.main,
				textDecoration: 'underline',
			},
		},
	},
	copyright: {
		...theme.typography.body2,
		color: theme.palette.text.disabled,
		textAlign: 'center',
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			textAlign: 'right',
		},
	},
	logoContainer: {
		marginTop: theme.spacing(2),
		height: theme.spacing(6),
		width: theme.spacing(6),
	},
}));

function FooterLayout1() {
	const footerTheme = useTypedSelector(({ fuse }) => fuse.settings.footerTheme);

	const styles = useStyles();

	return (
		<ThemeProvider theme={footerTheme}>
			<AppBar id="fuse-footer" position="relative" color="default">
				<Toolbar>
					<div className={styles.root}>
						<div className={clsx(styles.logoContainer, 'mx-auto sm:mx-0')}>
							<img src="/assets/imgs/logo.png" alt="logo" />
						</div>
						<ul className={styles.list}>
							<li>
								<Link to="/talk-to-us">FALE COM A GENTE</Link>
							</li>
							<li>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://www.ingles90dias.fun/politica-de-privacidade"
								>
									POLÍTICA DE PRIVACIDADE
								</a>
							</li>
							<li>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://www.ingles90dias.fun/termo-de-responsabilidade"
								>
									TERMO DE RESPONSABILIDADE
								</a>
							</li>
							<li>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="/assets/files/terms-of-service.txt"
								>
									TERMOS DE SERVIÇO
								</a>
							</li>

							<li className={styles.copyright}>
								Luz de Gaia Digital LTDA © Todos os direitos reservados
							</li>
						</ul>
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default FooterLayout1;
