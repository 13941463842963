import { collectionData } from '@fuse/utils/rxfire';
import { firestore } from 'app/services';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { Course } from './CourseFacade';

/*
    Enrollment Status
    // started - quando o boleto é impresso
    // active - quando esta pago
    // inactive - quando for cancelado ou expirar antes de ser aprovado
    // canceled - cancelado pelo cartao
    // past_due - vencido
    // expired - Reembolsado ou Chargeback
*/
export type EnrollmentStatus = 'pending' | 'active' | 'inactive' | 'expired';

export type EnrollmentRecurrencyPeriod = 0 | 7 | 30 | 60 | 90 | 180 | 360;

export interface Plan {
	name: string;
	recurrencyPeriod: EnrollmentRecurrencyPeriod;
	maxChargeCycles: number;
}

export interface Enrollment {
	id: string; // enrollmente id is hotmart subscriber code

	status: EnrollmentStatus; // enrollmente status

	user: Record<'id' | 'firstName' | 'lastName' | 'email', string>;
	class: { id: string; name: string; courses: { id: string; name: string }[] };
	plan: Plan;

	startedAt: firebase.firestore.Timestamp | null; // when purchase was realized
	activatedAt: firebase.firestore.Timestamp | null; // when purchase was compensed
	warrantyUntil: firebase.firestore.Timestamp | null; // when warranty ends

	updatedAt: firebase.firestore.Timestamp;
	createdAt: firebase.firestore.Timestamp;

	statusTitle: string;
	statusColor: string;
}

export const EnrollmentStatusMap = {
	pending: 'Aguardando Pagamento',
	active: 'Ativo',
	inactive: 'Inativo',
	expired: 'Expirado',
};

export const EnrollmentStatusColorMap = {
	pending: 'yellow',
	active: 'green',
	inactive: 'lightgray',
	expired: 'red',
};

export const EnrollmentStatusScoreMap = {
	active: 3,
	pending: 2,
	expired: 1,
	inactive: 0,
};

export function getStatusScore(status: EnrollmentStatus) {
	return EnrollmentStatusScoreMap[status] || -1;
}

export function normalizeEnrollment(enrollment: Enrollment) {
	if (enrollment) {
		enrollment.statusTitle = EnrollmentStatusMap[enrollment.status] || '';
		enrollment.statusColor = EnrollmentStatusColorMap[enrollment.status] || '';
	}
	return enrollment;
}

export function parseCourseEnrollment(enrollment: Enrollment, course: Course) {
	course.enrollment = normalizeEnrollment(enrollment);

	course.isEnrolled = !!course.enrollment;
	course.isActive = course.enrollment?.status === 'active';

	return course;
}

class EnrollmentFacade {
	private collection = firestore.collection('enrollments');

	getAllWithUserId$(id: string): Observable<Enrollment[]> {
		return collectionData(this.collection.where('user.id', '==', id), 'id');
	}
}

const instance = new EnrollmentFacade();

export default instance;
