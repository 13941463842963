import { RouteConfig } from 'app/configs';
import { authRoles } from 'app/auth';
import React from 'react';

const RegisterConfig: RouteConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/cadastrar',
			component: React.lazy(() => import('./Register')),
			exact: true,
		},
	],
};

export default RegisterConfig;
