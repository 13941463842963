import { defaultThemeOptions, mustHaveThemeOptions } from '@fuse/FuseDefaultSettings';
import { createTheme, responsiveFontSizes, Theme, ThemeOptions } from '@material-ui/core';
import { grey, orange } from '@material-ui/core/colors';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';

const fuseDark = {
	50: '#ECECEE',
	100: '#C5C6CB',
	200: '#9EA1A9',
	300: '#7D818C',
	400: '#5C616F',
	500: '#3C4252',
	600: '#353A48',
	700: '#2D323E',
	800: '#262933',
	900: '#1E2129',
	A100: '#C5C6CB',
	A200: '#9EA1A9',
	A400: '#5C616F',
	A700: '#2D323E',
};

const FuseThemesConfig: { [key: string]: ThemeOptions } = {
	default: {
		palette: {
			type: 'light',
			primary: fuseDark,
			secondary: {
				light: lightBlue[400],
				main: lightBlue[600],
				dark: lightBlue[700],
			},
			error: red,
		},
	},
	sunset: {
		palette: {
			type: 'light',
			primary: {
				light: '#ff908b',
				main: '#d0605e',
				dark: '#9b3134',
			},
			secondary: {
				light: '#c76a1d',
				main: '#ff994c',
				dark: '#ffca7b',
				contrastText: '#fff',
			},
			error: red,
		},
	},
	greeny: {
		palette: {
			type: 'light',
			primary: {
				light: '#6cabd4',
				main: '#387ca3',
				dark: '#005074',
			},
			secondary: {
				light: '#89f6cf',
				main: '#55c39e',
				dark: '#159270',
				contrastText: '#fff',
			},
			error: red,
		},
	},
	beach: {
		palette: {
			type: 'light',
			primary: {
				light: '#c4d8dd',
				main: '#93a7ab',
				dark: '#65787c',
				contrastText: '#fff',
			},
			secondary: {
				light: '#ffb281',
				main: '#f18153',
				dark: '#ba5228',
				contrastText: '#fff',
			},
		},
	},
	tech: {
		palette: {
			type: 'light',
			primary: {
				light: '#87efff',
				main: '#4dbce9',
				dark: '#008cb7',
				contrastText: '#fff',
			},
			secondary: {
				light: '#ffff83',
				main: '#d1e751',
				dark: '#9db516',
			},
		},
	},
	sweetHues: {
		palette: {
			type: 'light',
			primary: {
				light: '#d5c1eb',
				main: '#a391b9',
				dark: '#746389',
				contrastText: '#fff',
			},
			secondary: {
				light: '#90afd4',
				main: '#6080a3',
				dark: '#325474',
			},
		},
	},
	defaultDark: {
		palette: {
			type: 'dark',
			primary: fuseDark,
			secondary: grey,
			error: red,
		},
	},
	hfc: {
		palette: {
			type: 'dark',
			primary: {
				main: orange[600],
				contrastText: '#fff',
			},
			secondary: grey,
			background: {
				default: '#111',
			},

			error: red,
		},
	},
	deepOcean: {
		palette: {
			type: 'dark',
			primary: {
				light: '#8f53e7',
				main: '#5a24b4',
				dark: '#1e0083',
			},
			secondary: {
				light: '#ff61ff',
				main: '#fe00e9',
				dark: '#c600b6',
				contrastText: '#fff',
			},
		},
	},
	slate: {
		palette: {
			type: 'dark',
			primary: {
				light: '#86fff7',
				main: '#4ecdc4',
				dark: '#009b94',
			},
			secondary: {
				light: '#ff9d99',
				main: '#ff6b6b',
				dark: '#c73840',
				contrastText: '#fff',
			},
		},
	},
};

Object.entries(FuseThemesConfig).forEach(([key, value]) => {
	FuseThemesConfig[key] = responsiveFontSizes(
		createTheme(Object.assign(defaultThemeOptions, value, mustHaveThemeOptions)),
	);
});

export default FuseThemesConfig as { [key: string]: Theme };
