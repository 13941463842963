import { firestore } from 'app/services';
import { collectionData } from '@fuse/utils/rxfire';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { Video } from './VideoFacade';
import AuthFacade from './AuthFacade';

export interface Complete extends Video {
	completedAt: number;
}

class CompleteFacade {
	collection$ = AuthFacade.getUser$().pipe(
		map(user => user && firestore.collection(`users/${user.id}/completes`)),
		shareReplay({ bufferSize: 1, refCount: false }),
	);

	list$ = this.collection$.pipe(
		switchMap(collection => {
			if (!collection) return of([]);
			return collectionData<Complete>(collection.orderBy('completedAt', 'desc'), 'id');
		}),
		shareReplay({ bufferSize: 1, refCount: false }),
	);

	contains$(video: Video) {
		return this.list$.pipe(map(ls => !!ls.find(it => it.id === video.id)));
	}

	toggle(video: Video) {
		if (video.complete) {
			this.removeVideo(video);
		} else {
			this.addVideo(video);
		}
	}

	addVideo(video: Video) {
		this.collection$.pipe(take(1)).subscribe(collection => {
			if (collection) {
				const completedAt = Date.now();

				collection.doc(video.id).set({ ...video, completedAt });
			}
		});
	}

	removeVideo(video: Video) {
		this.collection$.pipe(take(1)).subscribe(collection => {
			if (collection) {
				collection.doc(video.id).delete();
			}
		});
	}
}

const instance = new CompleteFacade();

export default instance;
