import { firestore } from 'app/services';
import { map, shareReplay } from 'rxjs/operators';
import { collectionData } from '@fuse/utils/rxfire';
import { Picture } from './PictureFacade';
import firebase from 'firebase/app';
import { AccessControl, ChildrenType } from './CourseFacade';

export interface Module {
	id: string;
	parentId: string;
	position: number;
	slug: string;
	title: string;
	subtitle: string;
	description: string;
	thumbnail: string;
	locked: boolean;
	archived: boolean;
	layoutPosition: 'top' | 'middle' | 'bottom';
	childrenType: ChildrenType;
	workload?: number;
	videoCount?: number;
	picture: Picture;
	accessControl: AccessControl;

	createdAt: number | firebase.firestore.FieldValue;
	updatedAt: number | firebase.firestore.FieldValue;
	archivedAt: number | firebase.firestore.FieldValue;
}

class ModuleFacade {
	modulesCollection = firestore.collection('modules');

	modules$ = collectionData<Module>(this.modulesCollection, 'id').pipe(
		map(list => list.filter(item => item.archived === false)),
		map(list => list.sort((a, b) => a.position - b.position)),
		shareReplay({ bufferSize: 1, refCount: false }),
	);

	getModules$(parentId: string) {
		return this.modules$.pipe(map(list => list.filter(it => it.parentId === parentId)));
	}

	getModule$(slug: string, parentId: string) {
		return this.modules$.pipe(
			map(list => list.find(it => it.slug === slug && it.parentId === parentId) || null),
		);
	}
}

const instance = new ModuleFacade();

export default instance;
