import { combineReducers } from 'redux';
import fuse from './fuse';
import courses from './courses.reducer';
import player from './player.reducer';
import breadcrumb from './breadcrumbs.reducer';
import profileDialog from './profile-dialog.reducer';

const reducer = combineReducers({
	fuse,
	courses,
	player,
	breadcrumb,
	profileDialog,
});

export default reducer;
