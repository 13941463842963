import React from 'react';
import {
	Avatar,
	Button,
	Icon,
	ListItemIcon,
	ListItemText,
	Popover,
	MenuItem,
	makeStyles,
	Theme,
	Hidden,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useAuthentication } from 'app/contexts/AuthenticationContext';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: theme.spacing(6),
	},
	userName: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: theme.spacing(1),
			alignItems: 'start',
		},
		'& p': {
			textTransform: 'none',
			display: 'flex',
		},
	},
	listItemIcon: {},
	listItemText: {
		marginLeft: 0,
	},
	paper: {
		padding: theme.spacing(1, 0),
	},
}));

function UserMenu() {
	const { user, logout } = useAuthentication();

	const [userMenu, setUserMenu] = React.useState(null);

	const styles = useStyles();

	const userMenuClick = React.useCallback((event: any) => {
		setUserMenu(event.currentTarget);
	}, []);

	const userMenuClose = React.useCallback(() => {
		setUserMenu(null);
	}, []);

	const logoutUser = React.useCallback(() => {
		logout();
		userMenuClose();
	}, [userMenuClose, logout]);

	return (
		<React.Fragment>
			<Button className={styles.root} onClick={userMenuClick}>
				{user && (
					<>
						<Avatar src={user.avatarURL || ''} alt={user.firstName} />

						<div className={styles.userName}>
							<p>{user.firstName}</p>
						</div>
					</>
				)}

				{!user && (
					<div className={styles.userName}>
						<p>Desconectado</p>
					</div>
				)}

				<Hidden only="xs">
					<div className="ml-8 flex">
						<Icon>keyboard_arrow_down</Icon>
					</div>
				</Hidden>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					paper: styles.paper,
				}}
			>
				{!user ? (
					<React.Fragment />
				) : (
					<React.Fragment>
						<MenuItem component={Link} to="/profile" onClick={userMenuClose}>
							<ListItemIcon>
								<Icon>account_circle</Icon>
							</ListItemIcon>
							<ListItemText className={styles.listItemText} primary="Meu Perfil" />
						</MenuItem>
						<MenuItem onClick={logoutUser}>
							<ListItemIcon>
								<Icon>exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText className={styles.listItemText} primary="Sair" />
						</MenuItem>
					</React.Fragment>
				)}
			</Popover>
		</React.Fragment>
	);
}

export default UserMenu;
