function LoadingPage() {
	return (
		<div>
			<div className="loading-logo-shadow" />
			<img className="loading-logo" src="/assets/imgs/logo.png" alt="" />
			<div className="loading-spinner" />
		</div>
	);
}

export default LoadingPage;
