import BreadcrumbFacade, { BreadcrumbItem } from 'app/facades/BreadcrumbFacade';
import { Dispatch } from 'redux';
import { RootState } from 'app/store';

export const SET_BREADCRUMB_PREFIX = '[BREADCRUMB] SET BREADCRUMB PREFIX';
export const SET_BREADCRUMB = '[BREADCRUMB] SET BREADCRUMB';

export function setBreadcrumb(breadcrumb: (BreadcrumbItem | false | null | undefined)[]) {
	return {
		type: SET_BREADCRUMB,
		payload: breadcrumb.filter(s => s) as BreadcrumbItem[],
	};
}

export function setBreadcrumbPrefix(prefix: string) {
	return {
		type: SET_BREADCRUMB_PREFIX,
		payload: prefix,
	};
}

export function appendNavItem(item: BreadcrumbItem, parentId?: string) {
	return (dispatch: Dispatch, getState: () => RootState) => {
		const { breadcrumb } = getState().breadcrumb;

		return dispatch({
			type: SET_BREADCRUMB,
			payload: BreadcrumbFacade.appendBreadcrumbItem(breadcrumb, item, parentId),
		});
	};
}

export function updateNavItem(id: string, item: BreadcrumbItem) {
	return (dispatch: Dispatch, getState: () => RootState) => {
		const { breadcrumb } = getState().breadcrumb;
		return dispatch({
			type: SET_BREADCRUMB,
			payload: BreadcrumbFacade.updateBreadcrumbItem(breadcrumb, id, item),
		});
	};
}

export function removeNavItem(id: string) {
	return (dispatch: Dispatch, getState: () => RootState) => {
		const { breadcrumb } = getState().breadcrumb;
		return dispatch({
			type: SET_BREADCRUMB,
			payload: BreadcrumbFacade.removeBreadcrumbItem(breadcrumb, id),
		});
	};
}
