import React from 'react';
import { Subscription } from 'rxjs';

export default function useLoad<A, B, C, D, E>(
	fn: (first: A, second: B, third: C, fourth: D, fifth: E) => Subscription | undefined | null,
	first?: A | null,
	second?: B | null,
	third?: C | null,
	fourth?: D | null,
	fifth?: E | null,
) {
	React.useEffect(() => {
		if (first === null || second === null || third === null || fourth === null || fifth === null) return;
		const subscription = fn(first as A, second as B, third as C, fourth as D, fifth as E);
		return () => subscription?.unsubscribe();
		// eslint-disable-next-line
	}, [first, second, third, fourth, fifth]);
}
