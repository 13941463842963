import React from 'react';

function scrollToTopSmooth(ref: HTMLDivElement) {
	const totalScrollDistance = ref.scrollTop;

	if (totalScrollDistance > 0) {
		const duration = 500;
		const oldTimestamp = performance.now();

		function step(timestamp: number) {
			const coefficient = 1 - (timestamp - oldTimestamp) / duration;

			if (coefficient > 0) {
				ref.scrollTop = totalScrollDistance * coefficient;
				window.requestAnimationFrame(step);
			}
		}
		window.requestAnimationFrame(step);
	}
}

type Props = {
	scrollToTopOnChildChange?: boolean;
	className?: string;
	enable?: boolean;
	children?: React.ReactNode;
};

const FuseScrollbars = React.forwardRef<HTMLDivElement, Props>((props: Props, myRef: any) => {
	React.useEffect(() => {
		if (props.scrollToTopOnChildChange && myRef!.current!) {
			scrollToTopSmooth(myRef!.current!);
		}
	}, [props.children, props.scrollToTopOnChildChange, myRef]);

	return (
		<div id="scrollbar" className={props.className} ref={myRef}>
			{props.children}
		</div>
	);
});

export default FuseScrollbars;
