import React from 'react';
import { VelocityComponent, VelocityComponentProps } from 'velocity-react';
import 'velocity-animate/velocity.ui';

const FuseAnimate: React.FunctionComponent<VelocityComponentProps> = props => {
	const children = React.cloneElement(props.children, {
		style: {
			...props.style,
			visibility: 'hidden',
		},
	});
	return <VelocityComponent {...props} children={children} />;
};

FuseAnimate.defaultProps = {
	animation: 'transition.fadeIn',
	runOnMount: true,
	targetQuerySelector: null,
	interruptBehavior: 'stop',
	visibility: 'visible',
	display: 'block',
	duration: 300,
	delay: 50,
	easing: [0.4, 0.0, 0.2, 1],
};

export default React.memo(FuseAnimate);
