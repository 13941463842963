import { DialogProps } from '@material-ui/core';
import * as Actions from 'app/store/actions/fuse';
import { Reducer } from 'redux';
import { MyAction } from 'app/store';

interface DialogState {
	state: boolean;
	options: Partial<DialogProps> | null;
}

const initialState: DialogState = {
	state: false,
	options: null,
};

const dialog: Reducer<DialogState, MyAction> = (state = initialState, action: any) => {
	switch (action.type) {
		case Actions.OPEN_DIALOG: {
			return {
				state: true,
				options: action.payload,
			};
		}
		case Actions.CLOSE_DIALOG: {
			return {
				state: false,
				options: null,
			};
		}
		default: {
			return state;
		}
	}
};

export default dialog;
