import * as Actions from '../actions';
import { Reducer } from 'redux';
import { MyAction } from 'app/store';
import { Course } from 'app/facades/CourseFacade';

type CoursesState = {
	courses: (Course | null)[];
	ownedCourses: Course[];
	loading: boolean;
	loaded: boolean;
};

const initialState: CoursesState = {
	courses: [null, null, null, null, null],
	ownedCourses: [],
	loading: true,
	loaded: false,
};

const courses: Reducer<CoursesState, MyAction> = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_COURSES_LOADING: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case Actions.SET_COURSES: {
			return {
				courses: action.payload.courses,
				ownedCourses: action.payload.ownedCourses,
				loading: false,
				loaded: true,
			};
		}
		default: {
			return state;
		}
	}
};

export default courses;
