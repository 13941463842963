import React from 'react';
import { RouteConfig } from 'app/configs';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';

const ForgotPasswordConfig: RouteConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/forgot-password',
			component: () => <Redirect to="/esqueceu-a-senha" />,
		},
		{
			path: '/esqueceu-a-senha',
			component: React.lazy(() => import('./ForgotPassword')),
		},
	],
};

export default ForgotPasswordConfig;
