import { collectionData } from '@fuse/utils/rxfire';
import { firestore } from 'app/services';
import { combineLatest } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { CompleteFacade, FavoriteFacade } from '.';
import firebase from 'firebase/app';
import { Picture } from './PictureFacade';
import { Course } from './CourseFacade';
import { Module } from './ModuleFacade';

export interface Video {
	id: string;
	parentId: string;
	position: number;
	slug: string;
	title: string;

	provider: 'youtube' | 'vimeo';
	vimeo: number;
	youtube: string;

	audio: string;
	thumbnail: string;
	picture: Picture;
	stories: VideoStory[];

	duration: number;
	archived: boolean;
	attachments: VideoAttachment[];
	actions: VideoAction[];
	accessControl: VideoAccessControl;

	favorite: boolean;
	complete: boolean;
	selected: boolean;
}

export interface VideoAccessControl {
	type: string;
	openingDate: firebase.firestore.Timestamp | null;
	closingDate: firebase.firestore.Timestamp | null;
	daysAfterPurchase: number;
}

export interface VideoStory {
	title: string;
	text: string;
	audio: string;
}

export interface VideoAttachment {
	title: string;
	url: string;
	file: {
		name: string;
		size: number;
		type: string;
	};
}

export interface VideoAction {
	title: string;
	url: string;
	color: string;
	backgroundColor: string;
}

class VideoFacade {
	collection = firestore.collection('videos');

	getVideos$(parentId: string) {
		return collectionData<Video>(
			this.collection.where('parentId', '==', parentId).where('archived', '==', false),
			'id',
		).pipe(
			map(list => list.sort((a, b) => a.position - b.position)),
			switchMap(videos => {
				const videos$ = videos.map(video => {
					return combineLatest([
						FavoriteFacade.contains$(video),
						CompleteFacade.contains$(video),
					]).pipe(map(([favorite, complete]) => ({ ...video, favorite, complete } as Video)));
				});

				return combineLatest(videos$).pipe(startWith(videos));
			}),
		);
	}

	getVideo$(videoSlug: string, parent: Module | Course) {
		return this.getVideos$(parent.id).pipe(map(list => list.find(it => it.slug === videoSlug) || null));
	}
}

const instance = new VideoFacade();

export default instance;
