import * as Actions from '../actions';
import { Course } from 'app/facades/CourseFacade';
import { Reducer } from 'redux';
import _ from '@lodash';
import { MyAction } from 'app/store';
import { Video } from 'app/facades/VideoFacade';

type PlayerState = {
	videos: Video[];
	currentCourse: Course | null;
};

const initialState: PlayerState = {
	videos: [],
	currentCourse: null,
};

const player: Reducer<PlayerState, MyAction> = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_PLAYER_CURRENT_COURSE: {
			state.currentCourse = action.payload;
			return state;
		}
		case Actions.PATCH_PLAYER_CURRENT_COURSE: {
			state.currentCourse = _.merge(state.currentCourse || {}, action.payload);
			return state;
		}
		case Actions.REFRESH_PLAYER: {
			return state;
		}
		default: {
			return state;
		}
	}
};

export default player;
