import { Theme } from '@material-ui/core/styles';
import * as Actions from 'app/store/actions/fuse/index';
import LayoutConfig from 'app/layout/LayoutConfig';
import FuseSettingsConfig from 'app/configs/settingsConfig';
import FuseThemesConfig from 'app/configs/themesConfig';
import _ from '@lodash';
import { mainThemeVariations } from '@fuse/FuseDefaultSettings';
import { Reducer } from 'redux';
import { MyAction } from 'app/store';

const initialSettings = FuseSettingsConfig;
const initialThemes = FuseThemesConfig;

const initialState = {
	initial: initialSettings,
	defaults: initialSettings,
	current: initialSettings,
	themes: initialThemes,
	...getThemeOptions(initialThemes, initialSettings),
};

const settings: Reducer<typeof initialState, MyAction> = (
	state = initialState,
	action: { type: string; [key: string]: any },
) => {
	switch (action.type) {
		case Actions.SET_SETTINGS: {
			const newSettings = _.merge(
				{},
				state.current,
				action.value && action.value.layout && action.value.layout.style
					? { layout: { config: LayoutConfig.defaults } }
					: {},
				action.value,
			);
			const themes =
				newSettings.theme.main !== state.current.theme.main
					? { ...state.themes, ...updateMainThemeVariations(newSettings.theme.main) }
					: state.themes;
			return {
				...state,
				current: newSettings,
				themes,
				...getThemeOptions(themes, newSettings),
			};
		}
		case Actions.SET_INITIAL_SETTINGS: {
			return _.merge({}, initialState);
		}
		case Actions.SET_DEFAULT_SETTINGS: {
			const newSettings = _.merge(
				{},
				state.defaults,
				action.value && action.value.layout && action.value.layout.style
					? { layout: { config: LayoutConfig.defaults } }
					: {},
				action.value,
			);
			const themes =
				newSettings.theme.main !== state.defaults.theme.main
					? { ...state.themes, ...updateMainThemeVariations(newSettings.theme.main) }
					: state.themes;
			return {
				...state,
				defaults: _.merge({}, newSettings),
				current: _.merge({}, newSettings),
				themes,
				...getThemeOptions(themes, newSettings),
			};
		}
		case Actions.RESET_DEFAULT_SETTINGS: {
			const themes = { ...state.themes, ...updateMainThemeVariations(state.defaults.theme.main) };
			return {
				...state,
				defaults: _.merge({}, state.defaults),
				current: _.merge({}, state.defaults),
				themes,
				...getThemeOptions(themes, state.defaults),
			};
		}
		default: {
			return state;
		}
	}
};

export default settings;

function updateMainThemeVariations(mainTheme: keyof typeof FuseThemesConfig) {
	return mainThemeVariations(FuseThemesConfig[mainTheme]);
}

function getThemeOptions(themes: { [key: string]: Theme }, settingss: typeof FuseSettingsConfig) {
	return {
		mainTheme: themes[settingss.theme.main],
		navbarTheme: themes[settingss.theme.navbar],
		toolbarTheme: themes[settingss.theme.toolbar],
		footerTheme: themes[settingss.theme.footer],
		...updateMainThemeVariations(settingss.theme.main as keyof typeof FuseThemesConfig),
	};
}
