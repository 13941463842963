import React from 'react';
import { authRoles } from 'app/auth';
import { RouteConfig } from 'app/configs';

const DiscoverConfig: RouteConfig = {
	auth: authRoles.user,
	routes: [
		{
			path: '/discover',
			component: React.lazy(() => import('./Discover')),
			exact: true,
		},
	],
};

export default DiscoverConfig;
