import React, { useEffect } from 'react';
import { User } from 'app/facades/AuthFacade';
import { useHistory } from 'react-router-dom';
import { auth } from 'app/services';
import useLoad from '@fuse/hooks/useLoad';
import { AuthFacade } from 'app/facades';
import environment from 'environment';

const AuthenticationContext = React.createContext<
	[User | null | undefined, React.Dispatch<User | null>] | undefined
>(undefined);

function AuthenticationProvider({ children }: { children: React.ReactNode }) {
	const value = React.useState<User | null>();

	useLoad(() => AuthFacade.getUser$().subscribe(value[1]));

	const user = value[0];

	useEffect(() => {
		if (user?.language) {
			auth.languageCode = user.language || environment.lang;
		} else {
			auth.languageCode = environment.lang;
		}
	}, [user]);

	return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
}

function useAuthentication() {
	const context = React.useContext(AuthenticationContext);
	const history = useHistory();

	if (!context) {
		throw new Error(`useAuthentication must be used within a AuthenticationProvider`);
	}

	const [user, setUser] = context;

	const patchUser = (value: Partial<User>) => setUser({ ...user!, ...value });

	const logout = () => {
		history.push({
			pathname: '/',
		});

		auth.signOut();
	};

	const role = user ? 'user' : 'guest';

	const loading = user === undefined;

	return { user, setUser, patchUser, logout, role, loading };
}

export { AuthenticationProvider, useAuthentication };
