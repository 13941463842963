class FuseUtils {
	static setRoutes(config: any) {
		let routes = [...config.routes];

		if (config.settings || config.auth) {
			routes = routes.map(route => {
				let auth = config.auth ? [...config.auth] : [];
				auth = route.auth ? [...auth, ...route.auth] : auth;

				return {
					...route,
					settings: { ...config.settings, ...route.settings },
					auth,
				};
			});
		}

		return [...routes];
	}

	static generateRoutesFromConfigs(configs: any[]): any[] {
		let allRoutes: any[] = [];
		configs.forEach((config: any) => {
			allRoutes = [...allRoutes, ...this.setRoutes(config)];
		});
		return allRoutes;
	}

	static hasPermission(authArr: string[], role: string) {
		if (authArr === null || authArr === undefined) {
			return true;
		} else if (authArr.length === 0) {
			return !role;
		} else {
			return authArr.includes(role);
		}
	}
}

export default FuseUtils;
